<template>
  <div class="projectData">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="120px">
      <el-form-item label="所在地区：">
        <cascader-area
          :country="queryInfo.country"
          :province="queryInfo.province"
          :city="queryInfo.city"
          @searchArae="searchArae"
        />
      </el-form-item>
      <!-- <el-form-item label="部委：">
        <dic-radio-button
          :code.sync="queryInfo.ministries"
          type-code="043"
          query-name="ministries"
          name="部委"
          @onChange="onChange"
        />
      </el-form-item> -->
      <el-form-item label="标准状态：">
        <dic-radio-button
          :code.sync="queryInfo.standardStatus"
          type-code="042"
          query-name="standardStatus"
          name="标准状态"
          @onChange="onChange"
        />
      </el-form-item>
      <!-- <el-form-item label="行业类别：">
        <dic-tag-button
          :code.sync="queryInfo.industryCategoryListName"
          query-name="industryCategoryListName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item> -->
      <el-form-item label="领域类别：">
        <dic-tag-button
          :code.sync="queryInfo.fieldCategoryListName"
          query-name="fieldCategoryListName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-tag-button
          :code.sync="queryInfo.techCategoryListName"
          query-name="techCategoryListName"
          name="技术类别"
          tag="技术"
          @onChange="onChange"
        />
      </el-form-item>
      <!-- <el-form-item label="行业领域：">
        <dic-radio-button
          :code.sync="queryInfo.internationalStandardClassification"
          type-code="044"
          query-name="internationalStandardClassification"
          name="行业领域"
          @onChange="onChange"
        />
      </el-form-item> -->
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.tagName)"
        >
          {{ item.name }}：{{
            queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
            >添加</el-button
          >
          <import-data moduleName="企业标准数据" @fileClick="fileClick" />
          <el-button @click="dialogVisibleTalent = true" style="margin-right: 0"
            >报告上传<i class="el-icon-upload2"></i
          ></el-button>
          <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <popover @popoverClick="popoverClick" />
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <el-input
          placeholder="请输入标准号或标准名称"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="projectTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @sort-change="sortChange"
        :cell-style="set_cell_style"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column label="序号" align="center" width="80">
          <template v-slot="scope">
            <span
              >{{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dataIntegrity"
          label="数据完整度"
          sortable="custom"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <el-progress
                type="circle"
                :percentage="row.dataIntegrity"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="institutionName" label="企业名称" align="center">
        </el-table-column>
        <el-table-column prop="standardName" label="标准名称" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.standardName }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="standardId" label="标准号" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor:pointer;">
              <span>{{ row.standardId }}</span>
            </div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="所在地区" align="center">
          <template v-slot="{ row }">
            {{ row.country ? row.country + "-" : "" }}
            {{ row.province ? row.province + "-" : "" }}
            {{ row.city ? row.city : "" }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="standardStatus"
          label="标准状态"
          align="center"
        />
        <el-table-column prop="publishDate" label="发布时间" align="center">
          <template slot-scope="scope">
            {{
              Number(scope.row.publishDateYear)
                ? scope.row.publishDateYear
                : ""
            }}{{
              Number(scope.row.publishDateMonth)
                ? "-" + scope.row.publishDateMonth
                : ""
            }}{{
              Number(scope.row.publishDateDay)
                ? "-" + scope.row.publishDateDay
                : ""
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="implementationDate"
          label="实施日期"
          align="center"
        >
          <template slot-scope="scope">
            {{
              Number(scope.row.implementationDateYear)
                ? scope.row.implementationDateYear
                : ""
            }}{{
              Number(scope.row.implementationDateMonth)
                ? "-" + scope.row.implementationDateMonth
                : ""
            }}{{
              Number(scope.row.implementationDateDay)
                ? "-" + scope.row.implementationDateDay
                : ""
            }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="100"
          :formatter="formatTime"
        />
        <el-table-column label="操作" width="150" align="center">
          <template v-slot="{ row }">
            <button-table
              content="下载"
              @buttonClick="downloadClick(row)"
              icon="jr-iconjr-icon-download"
            />
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteItem(row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="批量上传"
      :visible.sync="dialogVisibleTalent"
      width="70%"
      @close="handleCloseTalent"
      center
      v-loading="loading"
      element-loading-text="正在上传中，请稍后"
      element-loading-spinner="el-icon-loading"
    >
      <el-row style="margin-bottom: 10px">
        <el-col :span="22">
          <upload-file-talent
            @filesDo="filesDo"
            ref="uploadTalent"
          ></upload-file-talent>
        </el-col>
      </el-row>
      <el-table
        :data="dataData17"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
        style="overflow: auto"
        height="350"
        center
      >
        <el-table-column
          type="index"
          width="200"
          :label="'序号' + '(总数为' + dataData17.length + ')'"
        >
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column prop="name" label="文件名(上传文件最大限制为100)">
        </el-table-column>
        <el-table-column prop="size" label="大小">
          <template slot-scope="scope">
            <span>{{ (scope.row.size / 1024 / 1024).toFixed(2) + "MB" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="padding: 0; color: red"
              @click="deleteTalentsData(scope.row, scope.$index)"
            >
              <i class="jr-iconjr-icon-delect" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadTalents">确 定</el-button>
        <el-button @click="dialogVisibleTalent = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Popover from "@/components/Popover.vue";
// import DataTime from '@/components/DataTime.vue'
import ImportData from "@/components/ImportData.vue";
import DicTagButton from "@/components/DicTagButton.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DataTime from "../../Product/com/dataTime.vue";
import { pagination } from "@/mixins/pagination";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import {
  getCompanyStandardList,
  companyStandardDeleteByIdList,
  importCompanyReport,
} from "@/api/standardKnowledge";
import { exportData } from "../../../utils/tool";
import UploadFileTalent from "@/components/UploadFileTalent.vue";
import CascaderArea from "@/components/CascaderAreaEnter.vue";
import { URL } from "../../../../config";
import axios from "axios";
export default {
  name: "ProjectData",

  mixins: [pagination],

  components: {
    ImportData,
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    DataTime,
    Popover,
    DicCheckboxGroup,
    UploadFileTalent,
    CascaderArea,
  },

  data() {
    return {
      loading: false,
      dialogVisibleTalent: false,
      dataData17: [],
      // 查询
      queryInfo: {
        columnName: "update_time",
        // columnNames:["update_time"],
        pageSize: 10,
        pageNum: 1,
        queryConditions: "", // 搜索查询条件
        standardStatus: "", //活动类别
        jobStatus: "", //费用类别
        // years: null, // 年
        // month: null, // 月
        // day: null, // 日
        id: [],
        country: "",
        province: "",
        city: "",
        // projectCountry: '', // 国家
        // projectProvince: '', // 省份
        // projectCity: '', // 城市
        // projectStage: '', // 项目阶段
        // reportTags: [], // 行业类别
        // reportTagsName: '', // 行业辅助标签
        // fieldId: [], // 领域类别
        activityField: [],
        // fieldIdName: '', // 领域辅助标签
        order: 1, // 0正序 1倒叙
        // size: '',
        size: "",
      },
      projectTable: [], // 表格数据
      tags: [
        {
          name: "标准状态",
          tagName: "standardStatus",
        },
        // {
        //   name: "行业类别",
        //   tagName: "industryCategoryListName",
        //   valLength: 0
        // },
        {
          name: "领域类别",
          tagName: "fieldCategoryListName",
          valLength: 0,
        },
        {
          name: "技术类别",
          tagName: "techCategoryListName",
          valLength: 0,
        },
      ],
      delete: {
        id: [],
        multiId: [],
      },
      dialogType: "行业类别",
      dialogVisible: false,
      editTableObj: {
        projectIndustryType: [], // 行业
        industryChildrenId: [], // 领域类别二级标签
        projectFieldType: [], // 领域
        fieldChildrenId: [], // 领域类别二级标签
      },
      valueI: 0,
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    this.search();
  },

  methods: {
    fileClick() {
      this.search();
    },
    async search() {
      const res = await getCompanyStandardList(this.queryInfo);

      if (res.code === 200) {
        this.projectTable = res.data.list;
        this.total = res.data.total;
        this.projectTable.forEach((el) => {
          if (el.dataIntegrity) {
            el.dataIntegrity = Number(el.dataIntegrity);
          } else {
            el.dataIntegrity = 0;
          }
        });
      }

      this.total = res.data.total;
    },
    searchArae(val) {
      this.queryInfo.country = val.country;

      this.queryInfo.province = val.province;

      if (
        val.city != "北京" &&
        val.city != "天津" &&
        val.city != "上海" &&
        val.city != "重庆"
      ) {
        this.queryInfo.city = val.city;
      }

      this.search();
    },
    updateCode(val, tag) {
      // this.nextTick(() => {
      switch (tag) {
        case "领域":
          this.editTableObj.fieldChildrenId = val;
          break;

        case "行业":
          this.editTableObj.industryChildrenId = val;
          break;

        default:
          break;
      }
      // })
    },
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.publishDateYear = "";
        this.queryInfo.publishDateMonth = "";
        this.queryInfo.publishDateDay = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.publishDateYear = obj.year + "";
        this.queryInfo.publishDateMonth = "";
        this.queryInfo.publishDateDay = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.publishDateYear = obj.year + "";
        this.queryInfo.publishDateMonth = obj.month + "";
        this.queryInfo.publishDateDay = "";
        this.search();
      } else {
        this.queryInfo.publishDateYear = obj.year + "";
        this.queryInfo.publishDateMonth = obj.month + "";
        this.queryInfo.publishDateDay = obj.day + "";
        this.search();
      }
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = [];

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === "行业类别" || name === "领域类别" || name === "技术类别") {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          });
        }
      }

      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex((el) => {
        return el.name === name;
      });

      switch (name) {
        case "行业类别":
          this.queryInfo.industryCategoryList = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.industryCategoryList =
              this.queryInfo.industryCategoryList.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "领域类别":
          this.queryInfo.fieldCategoryList = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.fieldCategoryList =
              this.queryInfo.fieldCategoryList.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }
          break;

        case "技术类别":
          this.queryInfo.techCategoryList = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.techCategoryList =
              this.queryInfo.techCategoryList.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        // case "国际标准分类":
        //   console.log(val);
        //   this.queryInfo.internationalStandardClassificationList = [val];

        //   if (checkboxVal && checkboxVal.length && val) {
        //     this.tags[result].valLength = checkboxVal.length;

        //     childrenVal = checkboxVal;

        //     this.queryInfo.internationalStandardClassificationList = this.queryInfo.internationalStandardClassificationList.concat(
        //       childrenVal
        //     );
        //   } else {
        //     this.tags[result].valLength = 0;
        //   }

        //   break;

        default:
          break;
      }

      this.search();
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);

      switch (name) {
        case "industryCategoryList":
          this.queryInfo.industryCategoryListName = "";
          this.queryInfo.industryCategoryList = [];
          break;
        // case "internationalStandardClassificationList":
        //   this.queryInfo.internationalStandardClassificationListName = "";
        //   this.queryInfo.internationalStandardClassificationList = [];
        //   break;

        case "techCategoryList":
          this.queryInfo.techCategoryListName = "";
          this.queryInfo.techCategoryList = [];
          break;
        case "fieldCategoryList":
          this.queryInfo.fieldCategoryList = "";
          this.queryInfo.fieldCategoryListName = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.search();
    },
    editItem(row) {
      // this.$router.push({
      //   name: 'RecruitInfo',
      //   params: {
      //     projectDataType: 1,
      //     id: row.id,
      //   },
      // })
      let text = this.$router.resolve({
        path: "/standardKnowledge/companyStandard/info",
        query: {
          personType: 1,
          id: row.id,
        },
      });
      window.open(text.href, "_blank");
    },

    addItem() {
      this.$router.push({
        name: "CompanyStandardInfo",
        query: {
          personType: 0,
        },
      });
    },

    // 点击文字切换正倒序
    caretText() {
      this.queryInfo.order = !this.queryInfo.order;

      if (this.queryInfo.order) {
        this.queryInfo.order = 1;
      } else {
        this.queryInfo.order = 0;
      }

      this.search();
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    // 正序
    caretTop() {
      this.queryInfo.order = 0;

      this.search();
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 1;

      this.search();
    },
    handleSelectionChange(val) {
      this.delete.multiId = val.map((item) => {
        return item.id;
      });
      this.queryInfo.idList = val.map((item) => {
        return item.id;
      });
    },
    _deleteProject(params) {
      this.$confirm("确认删除吗 ?", "提示")
        .then(async () => {
          const res = await companyStandardDeleteByIdList(params);

          if (res.code === 200) {
            this.$message.success("删除成功");
            this.search();
          }
        })
        .catch(() => {});
    },
    deleteItem(row) {
      this.delete.id.push(row.id);

      this._deleteProject(this.delete.id);
    },
    multiDelete() {
      if (this.delete.multiId.length) {
        this._deleteProject(this.delete.multiId);
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "dataIntegrity") {
        this.queryInfo.columnName = "data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    // 批量导出
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { idList: [], size: this.total });
      } else if (val == 0 && this.queryInfo.idList.length) {
        Object.assign(this.queryInfo, {
          idList: this.queryInfo.idList,
        });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }

      exportData(
        this.queryInfo, // 后台需要的参数
        "/companyStandard/downZip", // 路径
        "application/zip", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "企业标准知识数据.zip"
      );

      // this.queryInfo.id = []
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (column.label === "领域类别" || column.label === "行业类别") {
        return "cursor:pointer";
      }
    },
    handleCloseTalent() {
      this.$refs.uploadTalent.fileArray = [];
      (this.dataData17 = []), (this.errorMes = []);
    },
    deleteTalentsData(data, index) {
      this.dataData17.splice(index, 1);
      for (let i = 0; i < this.errorMes.length; i++) {
        if (this.errorMes[i] == index + 1) {
          for (let j = 0; j < this.errorMes.length; j++) {
            if (this.errorMes[j] > this.errorMes[i]) {
              this.errorMes[j] = this.errorMes[j] - 1;
            }
          }
          this.errorMes.splice(i, 1);
        }
      }
    },
    async uploadTalents() {
      if (this.errorMes.length > 0) {
        const errorMes1 = this.errorMes.join(",");
        this.$confirm(
          "第" + errorMes1.substring(0, 300) + "条数据格式错误",
          "提示",
          {
            dangerouslyUseHTMLString: true,
            customClass: "message-logout2",
          }
        );
      } else {
        if (this.dataData17.length <= 100) {
          const form = new FormData();
          this.dataData17.forEach((item, index) => {
            form.append("files", this.dataData17[index]);
          });
          this.loading = true;
          const res = await importCompanyReport(form);
          if (res.errorMsg) {
            const errorData = res.errorMsg.join(",");
            this.$confirm(errorData, "提示", {
              dangerouslyUseHTMLString: true,
              customClass: "message-logout2",
            });
            this.loading = false;
          }
          if (res.code == 500) {
            this.loading = false;
            this.$message.error(res.msg);
          }
          if (res.code == 200) {
            this.loading = false;
            this.$message.success(res.data);
          }
          // if(!res.errorMsg.length>0){
          //   this.$message.success("数据全部上传成功")
          //   this.loading = false
          // }else{
          //   this.$confirm(res.errorMsg, "提示")
          //     .then(() => {
          //     })
          //     .catch(() => {});
          //   this.loading = false
          // }
          this.search();
          this.dialogVisibleTalent = false;
        } else {
          this.$message.error("上传文件个数小于100");
        }
      }
    },
    // 批量上传
    filesDo(data) {
      let FileExt = data.map((item) => {
        return item.name.replace(/.+\./, "");
      });
      let flag = FileExt.map((item) => {
        return ["pdf"].includes(item);
      });
      let errorMe = [];
      flag.forEach((item, index) => {
        if (!item) {
          errorMe.push(index);
        }
      });
      this.dataData17 = data;
      this.errorMes = errorMe.map((item) => {
        return (item = item + 1);
      });
    },
    //下载产品附件
    //下载产品附件
    async downloadClick(row) {
      // const URL = 'http://192.168.0.6:8085/'

      if (row.fileName) {
        const fileNames = row.fileName.substring(
          row.fileName.lastIndexOf("/") + 1
        );
        axios({
          method: "GET",
          url: `${URL}/public/downloadUrlFile`,
          params: { urlStr: row.fileName },
          responseType: "blob",
          headers: {
            // 设置请求头
            Authorization: this.Authorization,
          },
        })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/msword",
            });
            let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载'链接
            a.download = fileNames + ".pdf"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
            document.body.removeChild(a); //释放标签
          })
          .catch((err) => {
            //this.$message.error('文件下载失败')
          });
      } else {
        this.$message("该条数据没有附件！");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
